<template>
  <div class="row mt-4 upload-videooz align-items-center">
    <div class="col-12 mb-4">
      <h4>{{ $t("promotionalImage") }}</h4>
      <p class="text-muted mt-1">
        {{ $t("promotionalImageDescription", sizes) }}
      </p>
    </div>
    <div
      v-if="image !== '' && image !== resultImage"
      class="col-12 d-flex align-items-center changed-video-image"
    >
      <div class="col-2">
        <img :src="image" height="50" width="100%" alt="image" />
      </div>
      <div style="font-size: 13px" class="col-10 col-md-8 col-lg-8">
        <div>{{ $t("modifiedImage") }}</div>
        <div>{{ $t("undoChange") }}</div>
      </div>
      <div class="col-12 col-md-2 mt-2 mt-md-0">
        <button
          @click="SET_TO_RESET_IMAGE()"
          class="btn w-100 btn-info btn-pill"
        >
          {{ $t("undo") }}
        </button>
      </div>
    </div>
    <div class="col-12 mb-4">
      <button
        class="btn btn-pill"
        :class="{ donate: sizes.width === 450 }"
        @click="(e) => SET_SIZES({ width: 450, height: 800 }, e)"
      >
        Dikey
      </button>
      <button
        class="btn btn-pill"
        :class="{ donate: sizes.width === 740 }"
        @click="(e) => SET_SIZES({ width: 740, height: 477 }, e)"
      >
        Yatay
      </button>
    </div>
    <div class="col-12 col-lg-6">
      <img v-if="resultImage !== ''" class="w-100" :src="resultImage" alt="" />
      <div v-else-if="imgSrc !== ''">
        <vue-cropper
          ref="cropper"
          :src="imgSrc"
          alt="Source Image"
          :aspectRatio="sizes.width / sizes.height"
          :viewMode="1"
          dragMode="move"
          :cropBoxResizable="false"
          :minCropBoxWidth="sizes.width"
          :minCropBoxHeight="sizes.height"
        >
        </vue-cropper>
        <p class="mt-4">
          <strong class="text-danger"> {{ $t("cropImageDesc") }} </strong>
        </p>
        <div class="btn-sbmit">
          <button
            :disabled="imageIsCropping"
            @click="
              imageIsCropping = true;
              CROP_IMAGE(sizes.width);
            "
          >
            <loading v-if="imageIsCropping"></loading>
            <span class="text-white" v-else> {{ $t("cropImage") }}</span>
          </button>
        </div>
      </div>
      <div v-else class="video-file">
        <h3>{{ $t("showPromotionalImage") }}</h3>
        <span @click="$refs.fileInput.click()">{{ $t("selectImage") }}</span>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="video-file">
        <i class="icon-graphics_05"></i>
        <h3>{{ $t("selectImage") }}</h3>
        <span> {{ $t("selectPublishedImage") }}</span>
        <p :class="`text-${alertBox.variant}`" v-if="alertBox.status">
          {{ alertBox.message }}
        </p>
        <form>
          <label for="file-upload" class="custom-file-upload">
            {{ $t("selectImage") }}
          </label>
          <input
            @change="SET_IMAGE"
            id="file-upload"
            accept="image/*"
            ref="fileInput"
            type="file"
          />
        </form>
      </div>
      <!--video-file end-->
    </div>
  </div>
</template>

<script>
import ImageCropper from "../../mixins/ImageCropper";

export default {
  mixins: [ImageCropper],
  methods: {
    SET_SIZES(sizes, e) {
      e.preventDefault();
      this.sizes = sizes;
    },
  },
};
</script>

<style></style>
