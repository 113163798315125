<template>
  <div
    class="col-12 d-flex align-items-center changed-video-image"
    :class="{ 'not-active': form.isActive === 0 }"
  >
    <div class="col-2 col-lg-1">
      <img
        :src="`/images/${form.isActive === 1 ? 'success' : 'danger'}.svg`"
        width="100%"
        alt=""
      />
    </div>
    <div class="col-10 col-md-8 col-lg-8">
      <div v-if="form.isActive === 1">{{ $t("productIsLive") }}</div>
      <div v-else>{{ $t("productIsNotLive") }}</div>
    </div>
    <div class="col-12 col-md-2 mt-2 mt-md-0">
      <button
        @click="SET_IS_ACTIVE"
        :class="`${form.isActive === 0 ? 'btn-success' : 'btn-danger'}`"
        class="btn w-100 btn-pill"
      >
        {{ form.isActive === 1 ? $t("unPublish") : $t("publish") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      default: 1,
    },
    form: {
      default: () => {},
    },
  },
  methods: {
    SET_IS_ACTIVE() {
      this.$router.replace({
        query: { ...this.$route.query, modal: `activated` },
      });
    },
  },
};
</script>
