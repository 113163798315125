var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('input',{attrs:{"type":"tel","title":_vm.title,"id":_vm.inputId,"disabled":_vm.disabled,"pattern":_vm.pattern,"required":_vm.mandotary ? 'true' : 'false',"name":"a_telno","placeholder":_vm.placeholder},domProps:{"value":_vm.priceInput},on:{"loadeddata":(e) => {
        e.target.value = _vm.SET_TARGET_VALUE(e.target.value, e, 'loaded');
        _vm.priceInput = e.target.value;
      },"focus":(e) => {
        e.target.value = _vm.SET_TARGET_VALUE(e.target.value, e, 'focus');
        _vm.priceInput = e.target.value;
      },"input":(e) => {
        e.target.value = _vm.SET_TARGET_VALUE(e.target.value, e, 'input');
        _vm.priceInput = e.target.value;
      }}}),_c('i',{staticClass:"c-form-control__icon o-form-control__icon",class:`icofont-phone`})])
}
var staticRenderFns = []

export { render, staticRenderFns }