<template>
  <div class="my-4 bg-light card p-4">
    <h3>{{ $t("deleteProductTitle") }}</h3>
    <p>
      {{ $t("deleteProductDescription") }}
    </p>
    <div class="my-4">
      <button @click="showModal = true" class="btn btn-pill btn-danger">
        {{ $t("deleteProductTitle") }}
      </button>
    </div>
    <Modal
      v-model="showModal"
      @ok="SEND_TO_REMOVE_PRODUCT"
      sm
      :title="$t('deleteProductTitle')"
    >
      {{ $t("deleteProduct") }}</Modal
    >
  </div>
</template>

<script>
import Modal from "../Shared/Modal/index.vue";
export default {
  components: { Modal },
  props: {
    product: {
      default: () => {},
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    getProductIndex() {
      const product = this.$store.state.Products.products.findIndex(
        (p) => p.id === Number(this.product.id)
      );
      if (product !== -1) return product;
      else return -1;
    },
  },
  methods: {
    SET_ALERT_BOX(variant, message) {
      this.$store.commit("SET_LOADING_MODAL", {
        loading: true,
        alertBox: {
          status: true,
          variant,
          message,
        },
      });
    },
    async SEND_TO_REMOVE_PRODUCT(loading) {
      loading(true);
      this.alertBox = await this.$store.dispatch("SEND_TO_REMOVE_PRODUCT", {
        productIndex: this.getProductIndex,
        id: this.product.id,
        path: this.product.type.storePath,
        commitRemovePath: this.product.type.commitRemovePath,
      });
      loading(false);
      this.isActiveModal = false;
      setTimeout(() => {
        if (this.alertBox.variant === "success") {
          //this.isActiveModal = false;
          this.$router.push(this.product.type.domain);
        }
      }, 2500);
    },
  },
};
</script>

<style></style>
