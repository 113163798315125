<template>
  <div class="my-4 bg-light card p-4">
    <h3>{{ $t("sharableProduct.title") }}</h3>
    <p>
      {{ $t("sharableProduct.description") }}
    </p>
    <p>
      <strong> {{ $t("sharableProduct.confirm") }} </strong>
    </p>
    <div class="my-4">
      <button
        @click.prevent="$emit('input', 1)"
        class="btn btn-pill"
        :class="{ donate: value === 1 }"
      >
        {{ $t("yes") }}
      </button>
      <button
        @click.prevent="$emit('input', 0)"
        class="btn btn-pill"
        :class="{ donate: value === 0 }"
      >
        {{ $t("no") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: 0,
    },
  },
};
</script>

<style></style>
