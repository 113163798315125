<template>
  <div class="my-4 bg-light card p-4">
    <h3>{{ $t("productOwner.title") }}</h3>
    <p class="mt-2">
      {{ $t("productOwner.description") }}
    </p>
    <p class="mt-2">
      <label for="users"
        ><strong> {{ $t("productOwner.select") }} </strong></label
      >
    </p>
    <v-select
      :clearable="false"
      ref="users"
      inputId="users"
      label="fullname"
      @option:selected="SELECTED_USER"
      :value="value"
      @search="GET_USERS"
      :options="users"
    >
      <template #no-options="{ search }">
        {{ $t("noFoundOptions", { search }) }}
      </template></v-select
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      users: [],
      lazyTimeout: -1,
    };
  },
  computed: {
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
  },
  props: {
    value: {
      default: () => {},
    },
    edit: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    SELECTED_USER(data) {
      this.$emit("input", data);
    },
    async GET_USERS(search, loading) {
      loading(true);
      if (this.lazyTimeout !== -1) clearTimeout(this.lazyTimeout);
      this.lazyTimeout = setTimeout(async () => {
        const [response, responseAdmin] = await Promise.all([
          this.$api.get(`Users?roleId=3&searchKey=${search}`),
          this.$api.get(`Users?roleId=1&searchKey=${search}`),
        ]);
        let result = [];
        if (response.message === "OK") {
          result = [
            ...result,
            ...response.data.items.map((it) => ({
              ...it,
              fullname: it.fullname + " (" + this.$t("instructor") + ")",
            })),
          ];
        }
        if (responseAdmin.message === "OK") {
          result = [
            ...result,
            ...responseAdmin.data.items.map((it) => ({
              ...it,
              fullname: it.fullname + " (" + this.$t("manager") + ")",
            })),
          ];
        }
        // this.$emit("input", this.getActiveUser);
        this.users = [...result];
        setTimeout(() => {
          if (!this.edit) this.$emit("input", this.getActiveUser);
          loading(false);
        }, 250);
      }, 1000);
    },
  },
  mounted() {
    setTimeout(() => {
      this.GET_USERS("", this.$refs.users.toggleLoading);
    }, 100);
  },
};
</script>

<style>
.v-select input {
  border: none !important;
  box-shadow: none !important;
  width: auto !important;
}
</style>
