<template>
  <div>
    <input
      type="tel"
      :title="title"
      :id="inputId"
      :disabled="disabled"
      :pattern="pattern"
      :value="priceInput"
      @loadeddata="
        (e) => {
          e.target.value = SET_TARGET_VALUE(e.target.value, e, 'loaded');
          priceInput = e.target.value;
        }
      "
      @focus="
        (e) => {
          e.target.value = SET_TARGET_VALUE(e.target.value, e, 'focus');
          priceInput = e.target.value;
        }
      "
      @input="
        (e) => {
          e.target.value = SET_TARGET_VALUE(e.target.value, e, 'input');
          priceInput = e.target.value;
        }
      "
      :required="mandotary ? 'true' : 'false'"
      name="a_telno"
      :placeholder="placeholder"
    />
    <i
      class="c-form-control__icon o-form-control__icon"
      :class="`icofont-phone`"
    ></i>
  </div>
</template>

<script>
export default {
  data() {
    return {
      first: true,
      priceInput: "",
    };
  },
  props: {
    inputId: {
      default: "name",
    },
    disabled: {
      default: false,
    },
    title: {
      default: "",
    },
    value: {
      default: "",
    },
    label: {
      default: "",
    },
    icon: {
      default: "",
    },
    placeholder: {
      default: "",
    },
    pattern: {
      default: null,
    },
    mandotary: {
      default: false,
      type: Boolean,
    },
    block: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    value() {
      if (this.first) {
        let d = this.value;
        this.priceInput = this.SET_TARGET_VALUE(d, { data: null });
        this.first = false;
      }
    },
  },
  methods: {
    SET_TARGET_VALUE(val, e) {
      let exactVal = this.first ? String(val).replace(/\./g, ",") : String(val);
      const numbers = [
        ".",
        ",",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
      ];
      if (
        [",", " ", "."].includes(String(val).charAt(0)) ||
        !numbers.some((n) => !exactVal.split("").includes(n))
      )
        return "";

      if (e.data && !numbers.includes(String(e.data)) && e.data !== null)
        return exactVal.replace(/[^0-9||.||,||]/g, "");
      if (
        !numbers.some((n) => !exactVal.split("").includes(n)) &&
        e?.inputType !== "insertFromPaste"
      )
        return exactVal.replace(/[^0-9||.||,||]/g, "");
      if (
        !this.chekPriceFormatWithRegex(exactVal) &&
        exactVal.includes(".") &&
        e?.inputType === "insertFromPaste"
      )
        exactVal = exactVal.replace(/\./, ",");
      exactVal = exactVal.replace(/\./g, "");
      exactVal = exactVal.replace(/ /g, "");

      if (exactVal.charAt(0) === "0" && exactVal.length > 1)
        exactVal = exactVal.substring(1);
      this.$emit("input", Number(exactVal.replace(/,/g, ".")));
      if (e.data !== null) return this.viewCurrencyFormat(exactVal);
      return this.viewCurrencyFormat(exactVal);
    },
    viewCurrencyFormat(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    chekPriceFormatWithRegex(val) {
      const regExp = /^(\d{1,3})(\d{1,3})(\d{1,3})(\d{1,3})$/;
      return regExp.test(val);
    },
  },
  mounted() {
    let d = this.value;
    this.priceInput = this.SET_TARGET_VALUE(d, { data: null });
  },
};
</script>

<style></style>
